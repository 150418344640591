import React, { useEffect, useState } from 'react';
import CheerioButton from '../../Components/CheerioButton';
import colors from '../../Utils/colors';
import EFBanner from './Components/EFBanner';
import SpinnerLoader from '../../Components/SpinnerLoader';
import images from '../../Utils/images';
import { useNavigate } from 'react-router-dom';
import {
  changeFormStatusById,
  deleteEmbeddedForm,
  downloadFormResponses,
  duplicateEmbeddedForm,
  getAllEmbeddedForms,
} from '../../Services';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';

const EFhome = () => {
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(false);
  const [formsBannerClose, setFormsBannerClose] = useState(false);
  const agentdata = useSelector((state) => state.main.agentdata);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsSch, setRowsSch] = useState([]);
  const [pageSch, setPageSch] = useState(0);
  const [rowsPerPageSch, setRowsPerPageSch] = useState(15);
  const [totalCount, setTotalCount] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    campaignListApiCall(0, +event.target.value); // call when row per page option is changed
  };

  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;

  useEffect(() => {
    getEmbeddedForms(page, rowsPerPage);
    if (agentdata && !agentdata?.permission?.embeddedForms) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);

  useEffect(() => {
    if (rowsPerPage) {
      getEmbeddedForms(page, rowsPerPage);
    }
  }, [page, rowsPerPage]);

  const getEmbeddedForms = (page, limit) => {
    let token = authtoken;
    getAllEmbeddedForms(token, page + 1, limit).then((res) => {
      if (res?.flag) {
        setRows(res?.data?.docs);
        setTotalCount(res?.data?.totalDocs);
        console.log('success get ===> ', res);
      } else {
        console.log('failure get ===> ', res);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const onclickEditAction = (_id) => {
    navigate(`/creator/embeddableForms/create/${_id}`);
  };

  const onclickDuplicateAction = (_id) => {
    let token = authtoken;
    let data = {
      _id: _id,
    };
    duplicateEmbeddedForm(token, data).then((res) => {
      if (res?.flag) {
        toast.success(res?.message, { position: 'top-right', autoClose: true });
        getEmbeddedForms(page, rowsPerPage);
        console.log('success ---> ', res);
      } else {
        toast.error(res?.message, { position: 'top-right', autoClose: true });
        console.log('failure ---> ', res);
      }
    });
  };

  const onclickDeleteAction = (_id) => {
    let token = authtoken;
    let data = {
      _id: _id,
    };
    deleteEmbeddedForm(token, data).then((res) => {
      if (res?.flag) {
        toast.success(res?.message, { position: 'top-right', autoClose: true });
        getEmbeddedForms(page, rowsPerPage);
        console.log('delete succ ===> ', res);
      } else {
        toast.error(res?.message, { position: 'top-right', autoClose: true });
        console.log('delete fail ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (rows.length > 0) {
      setTableData(true);
    }
  }, [rows]);

  const columns = [
    { id: 'formName', label: 'Form Name', width: '30%', align: 'start' },
    { id: 'formStatus', label: 'Form Status', width: '20%', align: 'center' },
    { id: 'formURL', label: 'Form URL', width: '30%', align: 'start' },
    { id: 'formAction', label: 'Action', width: '20%', align: 'center' },
  ];

  const handleToggleChange = (_id) => {
    let token = authtoken;
    changeFormStatusById(token, _id).then((res) => {
      if (res?.flag) {
        toast.success('Status changed successfully!', { position: 'top-right', autoClose: true });
        console.log('success res --->', res);
        getEmbeddedForms(page, rowsPerPage);
      } else {
        toast.error('Status change failed', { position: 'top-right', autoClose: true });
        console.log('fail res --->', res);
      }
      // getEmbeddedForms(page, rowsPerPage);
    });
  };

  const closeBtnAction = () => {
    localStorage.setItem('formsBannerClose', 'true');
    setFormsBannerClose(true);
  };

  const downloadFormResponsesApiCall = async (formId) => {
    try {
      // Define the token and make the API call
      let token = authtoken;
      const response = await downloadFormResponses(formId, token);
      console.log('resp', response);

      const csvData = response;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'form_responses.csv');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center"
        style={{
          width: '100%',
          height: '100vh',
          paddingBlock: 24,
          paddingInline: 30,
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h3>{'Embeddable Forms'}</h3>
          <div className="d-flex flex-row justify-content-between">
            {/* <CheerioButton
              btnText={'Export Report'}
              style={{ marginRight: '20px' }}
              backColor={colors.white}
              onclick={() => {
                downloadFormResponsesApiCall();
              }}
            /> */}
            <CheerioButton
              borderStyle={{ border: `1px solid ${colors.primary03}` }}
              backColor={colors.primary03}
              textStyle={{ color: colors.white }}
              btnText={'Create Form'}
              disabled={false}
              onclick={() => {
                navigate('/creator/embeddableForms/create');
              }}
            />
          </div>
        </div>
        <div className="my-2 w-100">
          <p style={{ color: colors.greys04 }}>
            {'Create & automate messages based on form responses. Generate a snippet '}
          </p>
        </div>
        {!(localStorage.getItem('formsBannerClose') === 'true') && (
          <EFBanner closeBtnAction={closeBtnAction} />
        )}

        <div
          className="d-flex flex-column justify-content-start align-items-start w-100 my-2"
          style={{ flex: 1 }}
        >
          {isLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
                style={{}}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : (
            <>
              {tableData ? (
                <>
                  <div
                    className="d-flex flex-column justify-content-between align-items-center w-100"
                    style={{
                      flex: 1,
                    }}
                  >
                    <TableContainer sx={{ flex: 1 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    width: column.width,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: colors.black,
                                    backgroundColor: colors.white04,
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => {
                            return (
                              <>
                                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                  {columns.map((column) => {
                                    let value = '';
                                    if (column.id == 'formName') {
                                      value = row?.formName;
                                    } else if (column.id == 'formStatus') {
                                      value = row?.active ? 'Active' : 'Inactive';
                                    } else if (column.id == 'formURL') {
                                      value = baseUrl + '/form/' + row?._id;
                                    }
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        {column.id == 'formAction' ? (
                                          <>
                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                              <Tooltip title={'Edit'}>
                                                <img
                                                  src={images.EditSender}
                                                  style={{
                                                    marginInline: 6,
                                                    height: 18,
                                                    width: 18,
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    onclickEditAction(row?._id);
                                                  }}
                                                ></img>
                                              </Tooltip>
                                              <Tooltip title={'Duplicate'}>
                                                <img
                                                  src={images.DesignDuplicate}
                                                  style={{
                                                    marginInline: 6,
                                                    height: 18,
                                                    width: 18,
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    onclickDuplicateAction(row?._id);
                                                  }}
                                                ></img>
                                              </Tooltip>
                                              <Tooltip title={'Delete'}>
                                                <img
                                                  src={images.DeleteBlack}
                                                  style={{
                                                    marginInline: 6,
                                                    height: 18,
                                                    width: 18,
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    onclickDeleteAction(row?._id);
                                                  }}
                                                ></img>
                                              </Tooltip>
                                              <Tooltip title={'Download Response'}>
                                                <img
                                                  src={images.DownloadIcon}
                                                  style={{
                                                    marginInline: 6,
                                                    height: 18,
                                                    width: 18,
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    downloadFormResponsesApiCall(row?._id);
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            </div>
                                          </>
                                        ) : column.id == 'formURL' ? (
                                          <p
                                            style={{
                                              fontSize: 14,
                                              color: colors.linkblue,
                                              textDecorationLine: 'underline',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              window.open(value);
                                            }}
                                          >
                                            {value}
                                          </p>
                                        ) : column.id === 'formStatus' ? (
                                          <>
                                            <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                              <Switch
                                                checked={row?.active}
                                                onChange={() => {
                                                  handleToggleChange(row?._id);
                                                }}
                                                sx={{ color: colors.primary03 }}
                                                size="small"
                                              />
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  color: colors.black,
                                                  marginInlineStart: 16,
                                                }}
                                              >
                                                {value}
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <p style={{ fontSize: 14, color: colors.black }}>
                                            {value}
                                          </p>
                                        )}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[15, 25, 100]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                    <img
                      src={images.NoDataForms}
                      style={{
                        height: 240,
                        width: 240,
                        objectFit: 'contain',
                      }}
                    ></img>
                    <p style={{ marginBlockStart: 12, color: colors.black, textAlign: 'center' }}>
                      {'No forms created!'}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EFhome;
