import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2AgentUpdate } from '../../../Services';
import { Typography } from '@mui/material';

const SubPermissionToggle = ({ title, setField, Checked, fieldName, disabled }) => {
  return (
    <>
      <div className="Row w-100" style={{ marginBlock: 4, }}>
         <p style={{ padding: 10, margin: 0, fontSize: 16, fontWeight: 400, color: colors.black,marginLeft:'10px' }}>
          &#8226; {title} 
        </p>
        <Form.Check
          disabled={disabled}
          type="switch"
          className="btncustom"
          defaultChecked={Checked}
          onChange={(e) => setField(fieldName, e.target.checked)}
          style={{ marginRight:'10px'}}
        />
      </div>
    </>
  );
};


const PermissionToggleSection = ({ title, permissions, setField, disabled, subPermissions, fieldName }) => {
  const [showSubPermissions, setShowSubPermissions] = useState(false);

  const handleToggle = (isChecked) => {
    setField(fieldName, isChecked);
    if (isChecked && subPermissions?.length > 0) {
      setShowSubPermissions(true);
    } else if (!isChecked) {
      setShowSubPermissions(false);
    }

    if (subPermissions?.length > 0) {
      subPermissions.forEach((sub) => {
        setField(sub.field, isChecked);
      });
    }
  };

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        padding: '-4px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '5px 10px',
          fontWeight: 600,
          fontSize: '16px',
          marginBottom: '6px',
        }}
        onClick={() => setShowSubPermissions(!showSubPermissions)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: colors.black,
              marginRight: '4px',
            }}
          >
            {title}
          </Typography>
          {subPermissions?.length > 0 && (
            <i
              className={`pi ${showSubPermissions ? 'pi-angle-up' : 'pi-angle-down'}`}
              style={{ fontSize: '16px' }}
            ></i>
          )}
        </div>

        <Form.Check
          type="switch"
          className="btncustom"
          checked={permissions?.[fieldName] || false}
          onChange={(e) => {
            e.stopPropagation();
            handleToggle(e.target.checked);
          }}
          disabled={disabled}
          style={{
            marginLeft: '50px',
            transform: 'scale(1.0)', 
          }}
        />
      </div>

      {showSubPermissions && subPermissions?.length > 0 && (
        <div>
          {subPermissions.map((sub) => (
            <SubPermissionToggle
              key={sub.field}
              title={sub.title}
              setField={setField}
              Checked={permissions?.[sub.field] || false}
              fieldName={sub.field}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};


const PermissionsModal = ({ setIsPermissionsModalOpen, selectedPeople }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [success, setSuccess] = useState(false);

  const PermissionsLabels = [
    {
      title: 'Contacts',
      field: 'contact',
      subPermissions: [
        { title: 'All Contacts', field: 'allContact' },
        { title: 'Contacts Report', field: 'contactReport' },
        { title: 'Labels', field: 'labels' },
        { title: 'Labels Report', field: 'labelsReport' },
      ],
    },
    {
      title: 'Campaigns',
      field: 'campaign',
      subPermissions: [
        { title: 'Campaigns Export', field: 'campaignExport' },
      ],
    },
    {
      title: 'Analytics',
      field: 'analytics',
      subPermissions: [
        { title: 'Global Analytics ', field: 'globalAnalytics' },
        { title: 'Global Analytics Report', field: 'globalAnalyticsReport' },
        { title: 'Campaign Analytics', field: 'campaignAnalytics' },
        { title: 'Campaign Analytics Report', field: 'campaignAnalyticsReport' },
        { title: 'Agent Analytics', field: 'agentAnalytics' },
        { title: 'Agent Analytics Report', field: 'agentAnalyticsReport' },
      ],
    },
    {
      title: 'WhatsApp Inbox',
      field: 'whatsappInbox',
      subPermissions: [
        { title: 'Messages Export ', field: 'messageExport' },
        { title: 'Report Export', field: 'reportExport' },
        { title: 'Chat Replies', field: 'chatReplies' },
        { title: 'Access all chats', field: 'allChats' },
      ],
    },
    {
      title: 'Template Library',
      field:  'templateLibrary'
    },
    {
      title: 'Email',
      field: 'email',
      subPermissions: [
        { title: 'Email Builder ', field: 'emailBuilder' },
        { title: 'Uplaod custom html', field: 'uploadCustomHtml' },
        { title: 'Manage Sender ID', field: 'manageSenderId' },
      ],
    }, 
    {
    title:'Integration',
    field:'integrations' 
    } ,
    {
      title:'Automation',
      field:'automation',
      subPermissions:[
        { title: 'Keyword Automation ', field: 'keywordAutomation' },
        { title: 'Workflows', field: 'workflow' },
        { title: 'Time trigger', field: 'timeTrigger' },
        { title: 'Chatbot', field: 'chatbot' },
      ],
    },
    {
      title:'Embedded Forms',
      field:'embeddedForms' 
      } ,
      {
        title:'Instagram Inbox',
        field:'instaReplies' 
      } ,
      {
        title:'WhatsApp Catalog',
        field:'whatsappCatalog' 
      } ,
  ];
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    setPermissions(selectedPeople?.permission);
  }, [selectedPeople]);

  console.log("selectedPeople?.permission",selectedPeople?.permission)
  const setField = (field, value) => {
    console.log('setField ===> ', field, value);
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [field]: value,
    }));
  };

  const UpdateAgentV2APICall = () => {
    const data = {
      name: selectedPeople?.name,
      email: selectedPeople?.email,
      phone: selectedPeople?.phone,
      role: selectedPeople?.role,
      permission: permissions,
      status: selectedPeople?.status,
    };
    const token = authtoken;
    V2AgentUpdate(data, token).then((res) => {
      if (res?.flag) {
        console.log('success update ', res);
        setSuccess(true);
      } else {
        console.log('error update ', res);
      }
    });
  };

  return (
    <>
    <div className="DarkBg" onClick={() => setIsPermissionsModalOpen(false)}>
      <div className="Center">
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: 500,
            backgroundColor: colors.white,
            border: `1px solid ${colors.borderwhite}`,
            paddingBlock: 30,
            paddingInline: 40,
            borderRadius: 16,
            maxHeight: '85vh',
            overflow: 'hidden',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{
              paddingBlockEnd: 16,
              borderBottom: `1px solid ${colors.borderwhite}`,
            }}
          >
            <h3
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 600,
                color: colors.black,
              }}
            >
              Manage Permissions
            </h3>
            <img
              src={images.CMClose}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              onClick={() => setIsPermissionsModalOpen(false)}
            />
          </div>

          {success ? (
            <>
              <img
                src={images.CATSuccess}
                style={{ height: 160, width: 160, objectFit: 'contain', marginBlock: 20 }}
              />
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 16,
                  fontWeight: 700,
                  color: colors.black,
                  textAlign: 'center',
                  width: '60%',
                }}
              >
                Permissions Updated
              </p>
              <CheerioButton
                borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                btnText={'Close'}
                backColor={colors.black}
                onclick={() => setIsPermissionsModalOpen(false)}
              />
            </>
          ) : (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.greys04,
                  width: '100%',
                }}
              >
                {`Name: ${selectedPeople?.name}`}
                <br />
                {`Email ID: ${selectedPeople?.email}`}
                <br />
                {`Phone no: ${selectedPeople?.phone}`}
              </p>

              <div style={{ width: '100%' }}>
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBottom: '16px' }}
                >
                  <p
                    style={{
                      margin: 0,
                      marginLeft:'9px',
                      padding: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {'Features'}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black,
                      marginRight:'16px'
                    }}
                  >
                    {'Access'}
                  </p>
                </div>
                <div
                style={{
                  width: '100%',
                  maxHeight: '50vh', 
                  overflowY: 'auto', 
                  padding: '0 10px',
                }}
              >
                {permissions &&
                  PermissionsLabels.map((item) => (
                    <PermissionToggleSection
                      key={item.field}
                      title={item.title}
                      permissions={permissions}
                      setField={setField}
                      disabled={!!agentdata?._id}
                      subPermissions={item.subPermissions}
                      fieldName={item.field}
                    />
                  ))}
                   </div>
              </div>

                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                  btnText={'Save Permissions'}
                  backColor={colors.primary03}
                  onclick={() => {
                    console.log('invite button click');
                    UpdateAgentV2APICall();
                  }}
                  // icon={images.MPPlus}
                  // iconStyle={{height}}
                  disabled={agentdata?._id ? true : false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsModal;
