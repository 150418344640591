import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: colors.pink, height: 8, width: 8, borderRadius: 4, top: '60%' };

const InstagramEventNode = ({ data, isConnectable }) => {
    return (
        <>
        <div className='d-flex flex-column justify-content-between align-items-center' 
            style={{ 
                border: `1px solid ${colors.borderwhite}`, 
                borderRadius: 16, 
                backgroundColor: colors.white01, 
                width: 250,
                overflow: 'hidden'
            }}>
            <div className='w-100 d-flex flex-row justify-content-between align-items-center' 
                style={{ 
                    paddingInline: 16, 
                    paddingBlock: 8, 
                    background: 'linear-gradient(90deg, #FF9A4A 0%, #FB5245 29.5%, #DA4186 64%, #AE30BC 100%)', 
                }}>
                <div className='d-flex align-items-center'>
                    <p style={{ 
                        padding: 0, 
                        margin: 0, 
                        fontSize: 16, 
                        fontWeight: 700, 
                        color: colors.white 
                    }}>
                        {(data?.instagramEvent ?? 'Instagram Event').length > 20
  ? (data?.instagramEvent ?? 'Instagram Event').substring(0, 20) + '...'
  : data?.instagramEvent ?? 'Instagram Event'}
                    </p>
                </div>
                <img src={images.Edit} style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}}></img>
            </div>
            <p style={{  
                    fontSize: 14,
                    marginTop:10,
                    marginBottom: 10,
                    textAlign: 'left', 
                    width: '100%',
                    paddingLeft: 16,  
                }}>
                   { data?.instagramEvent?`${data?.instagramEvent}`:'Select Event'}
                </p>
               { data?.instagramEvent && <hr style={{
            width: 'calc(100% - 20px)',
            border: 'none', 
            borderTop: '1px solid #E6E6E6', 
            margin: '0 auto',
            marginBottom: '10px' 
            }}/>}
       {data?.instagramEvent==="Incoming message in DM" && <div 
                style={{ 
                    width:'90%',
                    height:'90%',
                    padding: 10,
                    backgroundColor:'#FAFAFA',
                    borderRadius:10,
                    marginBottom: 16
                }}>

                {data?.conditions?.map((condition, index) => (
                    <div key={index}>
                        {condition.messageConditions.map((msgCondition, msgIndex) => (
                            <div key={msgIndex} 
                                style={{ 
                                    marginBottom: 8 
                                }}>
                                <p style={{ 
                                    margin: 0, 
                                    fontSize: 14, 
                                    paddingLeft:4,
                                }}>
                                    If message {msgCondition.conditionOperator}
                                </p>
                                <div style={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', 
                                    gap: 8, 
                                    marginTop: 8 
                                }}>
                                    {msgCondition.keywords.map((keyword, kIndex) => (
                                        <span key={kIndex} 
                                            style={{ 
                                                background: '#FFFFFF',
                                                padding: '4px 12px',
                                                border:'1px solid #CCCCCC',
                                                borderRadius: 20,
                                                fontSize: 14,
                                                color: '#666666'
                                            }}>
                                            {keyword.length > 30 ? keyword.substring(0, 25) + '...' : keyword}
                                        </span>
                                    ))}
                                </div>
                                {msgIndex < condition.messageConditions.length - 1 && (
                                <div style={{ 
                                    textAlign: 'center', 
                                    margin: '12px 0',
                                    fontSize: 14,
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: colors.borderwhite,
                                        zIndex: 1
                                    }} />
                                    <span style={{
                                        backgroundColor: colors.white02,
                                        padding: '0 8px',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {condition.type}
                                    </span>
                                </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>}

            {data?.instagramEvent==="Replied to Story" && <div 
                style={{ 
                    width:'90%',
                    height:'90%',
                    padding: 10,
                    backgroundColor:'#FAFAFA',
                    borderRadius:10,
                    marginBottom: 16
                }}>
                 {data?.storyDetails && <div style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 16
    }}>
     { data?.storyDetails?.media_type==='VIDEO' ? <video 
            src={data?.storyDetails?.media_url} 
            width='194px' 
            height='250px' 
            style={{
                borderRadius: 8,
                objectFit: 'cover'
            }}
        />:<img 
        src={data?.storyDetails?.media_url} 
        width='194px' 
        height='250px' 
        style={{
            borderRadius: 8,
            objectFit: 'cover'
        }}
    />}
    </div>}
                {data?.replyConditions?.map((condition, index) => (
                    <div key={index}>
                        {condition.messageConditions.map((msgCondition, msgIndex) => (
                            <div key={msgIndex} 
                                style={{ 
                                    marginBottom: 8 
                                }}>
                                <p style={{ 
                                    margin: 0, 
                                    fontSize: 14, 
                                    paddingLeft:4,
                                }}>
                                    If message {msgCondition.conditionOperator}
                                </p>
                                <div style={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', 
                                    gap: 8, 
                                    marginTop: 8 
                                }}>
                                    {msgCondition.keywords.map((keyword, kIndex) => (
                                        <span key={kIndex} 
                                            style={{ 
                                                background: '#FFFFFF',
                                                padding: '4px 12px',
                                                border:'1px solid #CCCCCC',
                                                borderRadius: 20,
                                                fontSize: 14,
                                                color: '#666666'
                                            }}>
                                            {keyword.length > 30 ? keyword.substring(0, 25) + '...' : keyword}
                                        </span>
                                    ))}
                                </div>
                                {msgIndex < condition.messageConditions.length - 1 && (
                                <div style={{ 
                                    textAlign: 'center', 
                                    margin: '12px 0',
                                    fontSize: 14,
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: colors.borderwhite,
                                        zIndex: 1
                                    }} />
                                    <span style={{
                                        backgroundColor: colors.white02,
                                        padding: '0 8px',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {condition.type}
                                    </span>
                                </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>}

            {data?.instagramEvent==="Commented on post or reel" && <div 
                style={{ 
                    width:'90%',
                    height:'90%',
                    padding: 10,
                    backgroundColor:'#FAFAFA',
                    borderRadius:10,
                    marginBottom: 16
                }}>
                     <div style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 16
    }}>
     { data?.postDetails?.media_type==='VIDEO' ? <video 
            src={data?.postDetails?.media_url} 
            width='194px' 
            height='250px' 
            style={{
                borderRadius: 8,
                objectFit: 'cover'
            }}
        />:<image 
        src={data?.postDetails?.media_url} 
        width='194px' 
        height='250px' 
        style={{
            borderRadius: 8,
            objectFit: 'cover'
        }}
    />}
    </div>
                {data?.postConditions?.map((condition, index) => (
                    <div key={index}>
                        {condition.messageConditions.map((msgCondition, msgIndex) => (
                            <div key={msgIndex} 
                                style={{ 
                                    marginBottom: 8 
                                }}>
                                <p style={{ 
                                    margin: 0, 
                                    fontSize: 14, 
                                    paddingLeft:4,
                                }}>
                                    If message {msgCondition.conditionOperator}
                                </p>
                                <div style={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', 
                                    gap: 8, 
                                    marginTop: 8 
                                }}>
                                    {msgCondition.keywords.map((keyword, kIndex) => (
                                        <span key={kIndex} 
                                            style={{ 
                                                background: '#FFFFFF',
                                                padding: '4px 12px',
                                                border:'1px solid #CCCCCC',
                                                borderRadius: 20,
                                                fontSize: 14,
                                                color: '#666666'
                                            }}>
                                            {keyword.length > 30 ? keyword.substring(0, 25) + '...' : keyword}
                                        </span>
                                    ))}
                                </div>
                                {msgIndex < condition.messageConditions.length - 1 && (
                                <div style={{ 
                                    textAlign: 'center', 
                                    margin: '12px 0',
                                    fontSize: 14,
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: colors.borderwhite,
                                        zIndex: 1
                                    }} />
                                    <span style={{
                                        backgroundColor: colors.white02,
                                        padding: '0 8px',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {condition.type}
                                    </span>
                                </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>}

            {!data?.options &&
                <CustomHandle 
                    type='source' 
                    position={Position.Right} 
                    id="Event_UserInit" 
                    style={handleStyle} 
                    isConnectable={1} 
                />
            }
        </div>
    </>
    )
}

export default InstagramEventNode